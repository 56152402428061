@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app {
  user-select: none;
  height: 100%;
  background-color: #010026;
  @apply text-white
}